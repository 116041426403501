#play-bar-container {
  .video-track {
    background: var(--cool-gray-400, #566976);
    height: 8px;
  }

  .rc-slider-track-1 {
    background: var(--GR-TP, linear-gradient(113deg, #7230ff 0%, #ff6c54 108.38%));
  }

  .rc-slider-handle {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    transform: translateX(0%) !important;
  }

  .rc-slider-handle-2 {
    transform: translateX(-2px) !important;
  }

  .dragger-read-only {
    transform: translate(-50%, -80%) !important;
  }
}
