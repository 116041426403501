.animated-logo {
  width: 45px;
  height: 45px;
}

#frame1 {
  visibility: hidden;
  animation: 4s show infinite;
}

#frame2 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.125s;
}

#frame3 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.25s;
}

#frame4 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.375s;
}

#frame5 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.5s;
}

#frame6 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.625s;
}

#frame7 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.75s;
}

#frame8 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.865s;
}

#frame9 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 0.95s;
}

#frame10 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.05s;
}

#frame11 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.15s;
}

#frame12 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.25s;
}

#frame13 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.35s;
}

#frame14 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.45s;
}

#frame15 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.55s;
}

#frame16 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.65s;
}

#frame17 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.75s;
}

#frame18 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.85s;
}

#frame19 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 1.95s;
}

#frame20 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.05s;
}

#frame21 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.15s;
}

#frame22 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.25s;
}

#frame23 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.35s;
}

#frame24 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.45s;
}

#frame25 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.55s;
}

#frame26 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.65s;
}

#frame27 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.75s;
}

#frame28 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.85s;
}

#frame29 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 2.95s;
}

#frame30 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 3.05s;
}

#frame31 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 3.15s;
}

#frame32 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 3.25s;
}

#frame33 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 3.35s;
}

#frame34 {
  visibility: hidden;
  animation: 4s show infinite;
  animation-delay: 3.45s;
}

@keyframes show {
  0% {
    visibility: visible;
  }
  12.5% {
    visibility: visible;
  }
  12.6% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}
