body {
  font-family: 'Cera Pro';
  overflow: hidden;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./fonts/Cera_Pro_Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Cera Pro Medium';
  src: url('./fonts/Cera_Pro_Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Cera Pro Bold';
  src: url('./fonts/Cera_Pro_Bold.ttf') format('truetype');
}

.chart-label {
  width: 300px;
}

.ant-cascader-menu {
  height: auto !important;
}

/* Scrollbar Settings */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #eff0f6;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #9eaeba;
}

::-webkit-scrollbar-thumb:hover {
  background: #83919c;
}

/* Input type="range" */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range'] {
    width: 100px;
    border-radius: 2px;
    z-index: 2;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 6px;
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-thumb {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    -webkit-appearance: none;
    background: white;
    cursor: pointer;
  }
}

li {
  margin: 8px 0;
  color: #445260;
}

.updateAIModal .ant-modal-content {
  padding: 0px;
  width: fit-content;
  border-radius: 30px;
}

.noPaddingModal .ant-modal-content {
  padding: 0px;
}

mark {
  background: #ff6c54;
  color: white;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #16b380 !important;
  background-color: #16b380 !important;
  border-color: #16b380 !important;
}

.ant-checkbox-checked:after {
  border-color: #16b380;
}
